
// import $ from 'jquery';

// $('a[href*=\\#]:not([href=\\#])').click(function () {
//   if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
//     && location.hostname == this.hostname) {
//     var $target = $(this.hash);
//     $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
// 	  var offsetTop = $(".c-header").height();
//     if ($target.length) {
//       var targetOffset = $target.offset().top - offsetTop;
//       $('html,body').animate({ scrollTop: targetOffset }, 1000);
//       return false;
//     }
//   }
// });