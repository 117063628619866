import $ from 'jquery';

$(function () {
	let header = $('#header');
	let navBar = $('#header-navbar');

	if (!header.length && !navBar.length) {
		return;
	}

	if (header.hasClass('is-fixed')) {
		$(window).on('load scroll', function () {
			header.css('left', $(window).scrollLeft() * -1 + 'px');
		});
	}

	if (!header.hasClass('is-style2')) {
		$(window).on('load scroll', function () {
			if ($(window).scrollTop() > 150) {
				header.addClass('is-style2');
			} else {
				header.removeClass('is-style2');
			}
		});
	}

	$(navBar).on('click', function () {
		$(this).toggleClass('is-active');
		header.toggleClass('navbar-active');
		if (header.hasClass('navbar-active')) {
			window.settings.bodyFixed(true);
		} else {
			window.settings.bodyFixed(false);
		}
	});

	$('.c-navi1__icon').on('click', function () {
		var parentElement = $(this).parent();

		if (!parentElement.hasClass('is-active')) {
			$(this).parent().toggleClass('is-active');
			$(this)
				.closest('.c-navi1__item')
				.find('.c-navi1__submenu')
				.stop()
				.slideToggle('slow');
			$(this).toggleClass('u--isOpen');
		} else {
			$(this)
				.closest('.c-navi1__item')
				.find('.c-navi1__submenu')
				.stop()
				.slideToggle('slow');
			$(this).toggleClass('u--isOpen');
		}
	});
});
