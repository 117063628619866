import $ from 'jquery';

const w = $(window);

$('.js-accordion').on('click', function () {
	$(this).parents('.js-accordion-item').toggleClass('is-active');

	$(this)
		.parents('.js-accordion-item')
		.children('.js-accordion-content')
		.slideToggle(400);

	$(this)
		.parents('.js-accordion-item')
		.children('.js-accordion-title')
		.toggleClass('is-active');
});

w.on('load resize', function () {
	if (w.outerWidth() < 768) {
		$('.c-list6__item1').addClass('js-accordion-item');
		$('.c-list6__heading1').addClass('js-accordion-title');
		$('.c-list6__reading1').addClass('js-accordion-content');
	} else {
		$('.c-list6__item1').removeClass('js-accordion-item is-active');
		$('.c-list6__heading1').removeClass('js-accordion-title is-active');
		$('.c-list6__reading1').removeClass('js-accordion-content is-active');
		$('.c-list6__reading1').removeAttr('style');
	}
});
