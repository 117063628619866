import $ from 'jquery';

$(function () {
	if ($('.js-heightMV').length) {
		if ($(window).outerWidth() < 768) {
			let height = $(window).height();
			$('.js-heightMV').css('height', height);
		}
	}
});
