import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.c-slider01', {
	loop: true,
	effect: 'fade',
	speed: 4000,
	autoplay: {
		delay: 7000,
		disableOnInteraction: false,
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});
